import React from "react";
import { useWhiteList } from "./useWhiteList";
import moment from "moment";

const WhiteList = () => {
  const {
    handleAddTanggalWhitelist,
    handleDeleteTanggalWhitelist,
    subMenu,
    setSubMenu,
    tanggal,
    setTanggal,
    tahun,
    setTahun,
    sortTanggalHariLibur,
    sortWhitelistHariMinggu,
    tanggalWhitelistHariMinggu,
    handleDeleteWhitelistHariMinggu,
    handleAddWhitelistHariMinggu,
    setTanggalWhitelistHariMinggu,
  } = useWhiteList();

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "50%",
          margin: "0 auto 24px auto",
        }}
      >
        <div
          className={subMenu === "hariMinggu" ? "menu_active" : "menu"}
          onClick={() => setSubMenu("hariMinggu")}
        >
          Hari Minggu
        </div>
        <div
          className={subMenu === "hariLibur" ? "menu_active" : "menu"}
          onClick={() => setSubMenu("hariLibur")}
        >
          Hari Libur
        </div>
      </div> */}
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "12px", color: "#cc3300", fontSize: "28px" }}>
          &#9888;
        </p>
        <div>
          <p style={{ maxWidth: "552px", color: "#cc3300", fontSize: "14px" }}>
            Jika tanggal sudah dimasukkan, pada{" "}
            <b style={{ fontWeight: 800 }}>hari H</b> sistem akan menambahkan
            secara otomatis <b style={{ fontWeight: 800 }}>1 Hari kerja</b> di
            Jam 2 Sore.
          </p>
          <p style={{ maxWidth: "552px", color: "#cc3300", fontSize: "14px" }}>
            Jika terdapat lembur pada hari yang telah dimasukkan,{" "}
            <b style={{ fontWeight: 800 }}>
              sistem akan membaca sebagai lembur
            </b>{" "}
            dan{" "}
            <b style={{ fontWeight: 800 }}>tidak akan menambahkan hari kerja</b>
            , sistem hanya menambahkan{" "}
            <b style={{ fontWeight: 800 }}>Lembur Libur</b>.
          </p>
        </div>
      </div>
      {subMenu === "hariMinggu" ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "12px 0 24px 0",
            }}
          >
            <div>
              <input
                className="input_box"
                type="date"
                placeholder="Pilih Tanggal"
                style={{ width: "112px" }}
                value={tanggalWhitelistHariMinggu}
                onChange={(e) => setTanggalWhitelistHariMinggu(e.target.value)}
              />
              <select
                style={{ width: "64px", height: "32px", margin: "0 0 0 12px" }}
                value={tahun}
                onChange={(e) => setTahun(e.target.value)}
              >
                <option disabled hidden>
                  Pilih Tahun
                </option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
            </div>
            <button
              className="cancel_button"
              style={{
                margin: "0 18px 0 0",
                background: "dodgerblue",
                color: "white",
                border: 0,
              }}
              onClick={() => handleAddWhitelistHariMinggu()}
            >
              Add
            </button>
          </div>

          <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
            <table className="table_dashboard">
              <tr>
                <th>No</th>
                <th>Tanggal</th>
                <th></th>
              </tr>
              {sortWhitelistHariMinggu &&
                sortWhitelistHariMinggu.map((data, index) => (
                  <tr>
                    <td>{index + 1}.</td>
                    <td>
                      {moment(data && data.tanggal)
                        .utcOffset("+0700")
                        .format("ddd MMM DD YYYY")}
                    </td>
                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        className="reset_button"
                        style={{ background: "red", margin: 0 }}
                        onClick={() =>
                          handleDeleteWhitelistHariMinggu(data.tanggal)
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "12px 0 24px 0",
            }}
          >
            <input
              className="input_box"
              type="date"
              placeholder="Pilih Tanggal"
              value={tanggal}
              onChange={(e) => setTanggal(e.target.value)}
            />
            <select
              style={{ width: "188px", height: "32px" }}
              value={tahun}
              onChange={(e) => setTahun(e.target.value)}
            >
              <option disabled hidden>
                Pilih Tahun
              </option>

              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
            </select>
            <button
              className="cancel_button"
              style={{
                margin: "0",
                background: "dodgerblue",
                color: "white",
                border: 0,
              }}
              onClick={() => handleAddTanggalWhitelist()}
            >
              Add
            </button>
          </div>

          <div style={{ width: "0px", minWidth: "100%", overflow: "auto" }}>
            <table className="table_dashboard">
              <tr>
                <th>No</th>
                <th>Tanggal</th>
                <th></th>
              </tr>
              {sortTanggalHariLibur &&
                sortTanggalHariLibur.map((data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.tanggal}</td>
                    <td style={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        className="reset_button"
                        style={{ background: "red", margin: 0 }}
                        onClick={() =>
                          handleDeleteTanggalWhitelist(data.tanggal)
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </>
      )}
    </>
  );
};
export default WhiteList;
