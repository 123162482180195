import React, { useState } from "react";
import "./dashboard.css";
import { useDashboard } from "./useDashboard";
import { Redirect } from "react-router-dom";
import Menu from "../../component/Menu/Menu";
import Absensi from "../SubMenu/Absensi";
import Payroll from "../SubMenu/Payroll";
import EditUser from "../SubMenu/EditUser";
import AddUser from "../SubMenu/AddUser";
import Kasbon from "../SubMenu/Kasbon";
import WhiteList from "../SubMenu/Whitelist/WhiteList";
import PatrolSecurity from "../SubMenu/PatrolSecurity";

const Dashboard = () => {
  const role = localStorage.getItem("user");
  const jsonRole = JSON.parse(role);

  const { statusCode, handleLogout } = useDashboard();
  const [menu, setMenu] = useState("absensi");

  return (
    <>
      {statusCode === 404 ? (
        <Redirect push to="/login" />
      ) : (
        <div className="flex_column">
          <div className="wrapper_menu">
            <Menu menu={menu} setMenu={setMenu} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <p style={{ margin: 0 }}>
                Hello {jsonRole.name.split(" ")[0]}, TIM {jsonRole.tim}
              </p>
              <div
                className="logout_btn"
                onClick={() => handleLogout()}
                style={{ width: "52px" }}
              >
                Logout
              </div>
            </div>
          </div>
          <div className="container_dashboard">
            {menu === "absensi" ? (
              <Absensi />
            ) : menu === "payroll" ? (
              <Payroll />
            ) : menu === "editUser" ? (
              <EditUser />
            ) : menu === "addUser" ? (
              <AddUser />
            ) : menu === "kasbon" ? (
              <Kasbon />
            ) : menu === "whiteList" ? (
              <WhiteList />
            ) : menu === "patrolSecurity" ? (
              <PatrolSecurity />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Dashboard;
