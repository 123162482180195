import { useState, useEffect } from "react";
import { dayDefault, monthDefaultFormat } from "../../../util/formatDate";
import axios from "axios";
import moment from "moment";

export const useWhiteList = () => {
  const getToken = localStorage.getItem("auth")
    ? localStorage.getItem("auth")
    : null;

  const getTim = localStorage.getItem("user");
  const jsonTim = JSON.parse(getTim);

  const [dataTanggal, setDataTanggal] = useState();
  const [refresh, setRefresh] = useState(false);
  const [refreshTanggal, setRefreshTanggal] = useState(false);
  const [subMenu, setSubMenu] = useState("hariMinggu");
  const [tanggal, setTanggal] = useState(
    moment().utcOffset("+0700").format("YYYY-MM-DD")
  );
  const [tahun, setTahun] = useState(moment().utcOffset("+0700").year());

  const [dataWhitelistHariMinggu, setDataWhitelistHariMinggu] = useState();
  const [tanggalWhitelistHariMinggu, setTanggalWhitelistHariMinggu] = useState(
    moment().utcOffset("+0700").format("YYYY-MM-DD")
  );

  let tanggalPick = new Date(tanggal);
  let day = tanggalPick.getDay();
  let date = tanggalPick.getDate();
  let month = tanggalPick.getMonth();
  let year = tanggalPick.getFullYear();
  const fullDate = `${dayDefault[day]} ${monthDefaultFormat[month]} ${date} ${year}`;

  const handleAddTanggalWhitelist = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "addTanggalWhitelist",
          tanggal: fullDate,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefreshTanggal(true);
        setTanggal(moment().utcOffset("+0700").format("YYYY-MM-DD"));
      }
    } catch (error) {
      return error;
    }
  };

  const handleGetTanggalWhitelist = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "getTanggalWhitelist",
          tim: jsonTim.tim,
          tahun: tahun.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setDataTanggal(response.data);
        setTanggal(moment().utcOffset("+0700").format("YYYY-MM-DD"));
      }
    } catch (error) {
      return error;
    }
  };
  const sortTanggalHariLibur =
    dataTanggal &&
    dataTanggal.sort(function (a, b) {
      return new Date(b.tanggal) - new Date(a.tanggal);
    });

  const handleDeleteTanggalWhitelist = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "deleteTanggalWhitelist",
          tanggal: data,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefreshTanggal(true);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleGetTanggalWhitelist();
    setRefreshTanggal(false);
  }, [refreshTanggal, tahun]);

  const handleAddWhitelistHariMinggu = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "addWhitelistHariMinggu",
          tanggal: tanggalWhitelistHariMinggu,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefresh(true);
        // setTanggalWhitelistHariMinggu(
        //   moment().utcOffset("+0700").format("YYYY-MM-DD")
        // );
      }
    } catch (error) {
      return error;
    }
  };

  const handleGetWhitelistHariMinggu = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "getWhitelistHariMinggu",
          tim: jsonTim.tim,
          tahun: tahun.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setDataWhitelistHariMinggu(response.data);
        // setTanggalWhitelistHariMinggu(
        //   moment().utcOffset("+0700").format("YYYY-MM-DD")
        // );
      }
    } catch (error) {
      return error;
    }
  };
  const sortWhitelistHariMinggu =
    dataWhitelistHariMinggu &&
    dataWhitelistHariMinggu.sort(function (a, b) {
      return new Date(b.tanggal) - new Date(a.tanggal);
    });

  const handleDeleteWhitelistHariMinggu = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/whiteList`,
        {
          type: "deleteWhitelistHariMinggu",
          tanggal: data,
          tim: jsonTim.tim,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken}`,
          },
        }
      );
      if (response.status === 200) {
        setRefresh(true);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    handleGetWhitelistHariMinggu();
    setRefresh(false);
  }, [refresh, tahun]);

  return {
    handleAddTanggalWhitelist,
    handleDeleteTanggalWhitelist,
    subMenu,
    setSubMenu,
    tanggal,
    setTanggal,
    tahun,
    setTahun,
    sortTanggalHariLibur,
    sortWhitelistHariMinggu,
    tanggalWhitelistHariMinggu,
    setTanggalWhitelistHariMinggu,
    handleDeleteWhitelistHariMinggu,
    handleAddWhitelistHariMinggu,
  };
};
