import "./Menu.css";

const Menu = (props) => {
  const { menu, setMenu } = props;
  return (
    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
      <div
        className={menu === "absensi" ? "menu_active" : "menu"}
        onClick={() => setMenu("absensi")}
      >
        Absensi
      </div>
      <div
        className={menu === "payroll" ? "menu_active" : "menu"}
        onClick={() => setMenu("payroll")}
      >
        Payroll
      </div>
      <div
        className={menu === "editUser" ? "menu_active" : "menu"}
        onClick={() => setMenu("editUser")}
      >
        Edit User
      </div>
      <div
        className={menu === "addUser" ? "menu_active" : "menu"}
        onClick={() => setMenu("addUser")}
      >
        Add User
      </div>
      {/* <div
        className={menu === "kasbon" ? "menu_active" : "menu"}
        onClick={() => setMenu("kasbon")}
      >
        Kasbon
      </div> */}
      <div
        className={menu === "whiteList" ? "menu_active" : "menu"}
        onClick={() => setMenu("whiteList")}
      >
        Whitelist
      </div>
      <div
        className={menu === "patrolSecurity" ? "menu_active" : "menu"}
        onClick={() => setMenu("patrolSecurity")}
      >
        Patrol
      </div>
    </div>
  );
};
export default Menu;
